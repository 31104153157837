import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import Grid from "@material-ui/core/Grid"
import FormLabel from "@material-ui/core/FormLabel"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import RadioGroup from "@material-ui/core/RadioGroup"
import Radio from "@material-ui/core/Radio"
import Paper from "@material-ui/core/Paper"
import Avatar from "@material-ui/core/Avatar"
import LokalizedLink from "../components/localizedLink"
import Typography from "@material-ui/core/Typography"
import { capitalize } from "@material-ui/core/utils"
import "./card.css"
import Title from "../components/EmotionCard/Title"

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  paper: {
    height: 140,
    width: 100
  },
  control: {
    padding: theme.spacing(2)
  },
  large: {
    width: theme.spacing(10),
    height: theme.spacing(10)
  }
}))

const AvatarTextListItem = ({ post }) => {
  const [spacing, setSpacing] = React.useState(2)
  const classes = useStyles()

  const handleChange = event => {
    setSpacing(Number(event.target.value))
  }
  const { slug, title, excerpt } = post
  //postt.description = "This is the post description should be short and concise"

  return (
    <Grid container className={classes.root} spacing={2}>
      <Grid item xs={12}>
        <Grid container justify="center" spacing={spacing}>
          <Grid item xs={4}>
            <Avatar
              alt="Remy Sharp"
              src={post.featuredImage.childImageSharp.fluid.src}
              className={classes.large}
            />
          </Grid>
          <Grid item xs={8}>
            <LokalizedLink
              underline="none"
              style={{ textDecoration: "none" }}
              to={`/${slug}`}
            >

              <Typography variant="subtitle1" color="textPrimary" component="p" style={{ textAlign: "right"}}>
                <Title> {title} </Title>
              </Typography>

            </LokalizedLink>

            <Typography variant="subtitle2" color="textSecondary" component="p">
              {excerpt}
              <LokalizedLink underline="none" to={`/${slug}`}>
                {"more"}
              </LokalizedLink>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default AvatarTextListItem
